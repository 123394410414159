html,
#root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body {
  height: 100%;
  font-family: 'Arial', sans-serif;
  background-color: #2c3e50;
  color: #ecf0f1;
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header,
footer {
  background-color: #34495e;
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
}

header h1,
footer p {
  margin: 0;
}

.app-content {
  text-align: center;
  padding: 20px;
}

.btn {
  background-color: #e74c3c;
  border: none;
  color: #ecf0f1;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.btn:hover {
  background-color: #c0392b;
}


.no-content {
  display: flex;
  flex-wrap: wrap;
  min-height: 70vh;
}

.card {
  background-color: #3b4a69;
  ;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 20px;
  max-width: 300px;
  height: 200px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.column {
  display: flex;
  flex-direction: column;
}

.label {
  /* font-weight: bold;
  margin-bottom: 5px; */
  font-weight: bold;
  color: #ecf0f1;
  /* 白色标题 */
  margin-bottom: 5px;
}

.value {
  color: #bdc3c7;
}

.footer {
  background-color: #72879c;
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.card-container {
  flex-grow: 1;
  /* 占据剩余空间 */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
}

.card-container::-webkit-scrollbar {
  display: none;
}
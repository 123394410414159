/* verify.css */
.verify-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-content {
  /* display: flex; */
  /* flex-direction: column; */
  background: rgba(255, 255, 255, 0.7);
  /* padding: 20px; */
  /* padding-top: 20px; */
  padding-bottom: 20px;
  /* padding-right: 30px; */
  padding-left: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

button {
  background-color: #8fa4c0;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  margin-right: 10px;
}

button:hover {
  background-color: #7b9bcd;
  /* Slightly darker shade for hover */
}

button:active {
  background-color: #4f739b;
  /* Darker shade for active state */
}

.digit-group {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  max-width: 300px;
  margin: 0 auto;
}

.digit-input {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  border: 2px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

.digit-input:focus {
  border-color: #7b9bcd; /* Match button color for consistency */
}

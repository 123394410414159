.marquee-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: hsl(0, 0%, 20%);
    color: white;
    padding-top: 5px;
    padding-bottom: 35px;
}

.marquee {
    position: absolute;
    display: inline-block;
    white-space: nowrap;
    animation: marquee linear infinite;
    text-align: center;
}

.marquee p {
    display: inline-block;
    padding-left: 100%;
    font-size: 1.2em;
    margin: 0;
}

@keyframes marquee {
    0% {
        transform: translateX(100vw);
    }

    100% {
        transform: translateX(-100vw);
    }
}